/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EdDict from '@/components/EdDict/Index';
import XInput from 'vux/src/components/x-input';
import Group from 'vux/src/components/group';
import XButton from 'vux/src/components/x-button';
import ViewBox from 'vux/src/components/view-box';
import XTextarea from 'vux/src/components/x-textarea';
export default {
  name: 'AgentForm',
  components: {
    XInput: XInput,
    Group: Group,
    XButton: XButton,
    ViewBox: ViewBox,
    XTextarea: XTextarea,
    EdDict: EdDict
  },
  data: function data() {
    return {
      currDate: '',
      doType: '',
      btnText: '',
      area: [],
      form: {
        companyName: '',
        deviceNo: '',
        devicePrice: '',
        area: '',
        remark: '',
        userSerial: this.$utils.Store.getItem('userSerial')
      }
    };
  },
  computed: {},
  methods: {
    submitData: function submitData() {
      var _this = this;

      if (!this.$refs.companyName.valid) {
        this.$utils.Tools.toastShow('请输入设备名称');
        return;
      }

      if (!this.$refs.deviceNo.valid) {
        this.$utils.Tools.toastShow('请输入型号');
        return;
      }

      if (!this.$refs.devicePrice.valid) {
        this.$utils.Tools.toastShow('请输入报价');
        return;
      }

      this.form.area = this.area[0];

      if (this.doType === 'update') {
        this.form.id = this.$route.query.xh;
        this.$api.Agent.updateByPrimaryKey(this.form).then(function (response) {
          var code = response.code,
              msg = response.msg;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);
          } else {
            _this.$utils.Tools.toastShow(msg);

            setTimeout(function () {
              _this.$router.replace({
                path: '/AgentList'
              });

              _this.$router.go(-1);
            }, 1500);
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      } else {
        this.$api.Agent.insert(this.form).then(function (response) {
          var code = response.code,
              msg = response.msg;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);
          } else {
            _this.$router.replace({
              path: '/AgentList'
            });

            _this.$router.go(-1);

            _this.$utils.Tools.toastShow(msg);
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }
    },
    getRecordInfo: function getRecordInfo() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Agent.selectByPrimaryKey(this.$route.query.xh).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.form = data;

          if (_this2.form.area) {
            _this2.area = [_this2.form.area];
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.doType = this.$route.query.operation;

    if (this.$route.query.xh) {
      this.getRecordInfo();
      this.btnText = '修改';
    } else {
      this.btnText = '提交';
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};