/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//
import PopupPicker from 'vux/src/components/popup-picker';
export default {
  name: 'EdDict',
  components: {
    PopupPicker: PopupPicker
  },
  props: {
    value: Array,
    title: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    dicType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      select: [],
      list: [[{
        name: '',
        value: ''
      }]]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDictInfo();
  },
  methods: {
    getDictInfo: function getDictInfo() {
      var _this = this;

      var params = {
        typeCode: this.dicType,
        useFlag: 1
      };
      this.$api.Dict.getDictInfo(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          var list = [[]];

          var _iterator = _createForOfIteratorHelper(data),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              list[0].push({
                name: item.dicName,
                value: item.dicCode
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          _this.list = list;
        }
      }).catch(function () {});
    }
  },
  watch: {
    select: function select(val) {
      this.$emit('input', val || '');
    },
    value: {
      handler: function handler(val) {
        this.select = val === 'null' ? [] : val;
      },
      immediate: true
    }
  }
};